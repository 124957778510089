@charset "UTF-8";
/*
  Если элементы первого уровня равны по ширине,
  т.е. ширина будет указана в процентах относительно ширины родительского контейнера,
  ставим true, в противном случае false.
*/
.color-silver {
  color: #C4C4C4; }

.color-dim-gray {
  color: #676767; }

.color-pompadour {
  color: #7C1649; }

.color-jazzberry-jam {
  color: #A00F43; }

.color-crimson_2 {
  color: #E2023A; }

.color-black {
  color: #000000; }

.color-onyx {
  color: #111111; }

.color-dark-jungle {
  color: #222222; }

.color-mine-shaft {
  color: #333333; }

.color-tundora {
  color: #444444; }

.color-davys-grey {
  color: #555555; }

.color-ironside-gray {
  color: #666666; }

.color-tapa-gray {
  color: #777777; }

.color-taupe-gray {
  color: #888888; }

.color-mountain-mist {
  color: #999999; }

.color-dark-gray {
  color: #aaaaaa; }

.color-gray {
  color: #bbbbbb; }

.color-celeste {
  color: #cccccc; }

.color-light-gray {
  color: #dddddd; }

.color-eallery {
  color: #eeeeee; }

.color-white {
  color: #ffffff; }

.color-yellow {
  color: #ffff00; }

.color-navy-blue {
  color: #0079c1; }

.color-prussian-blue {
  color: #00426a; }

.color-error {
  color: #ec1c24; }

.color-lima {
  color: #78b226; }

.color-grey-chateau {
  color: #939597; }

.color-orange {
  color: #fea300; }

.color-malachite {
  color: #20bc55; }

.color-light-sea-green {
  color: #1aa3a7; }

.color-forest-green {
  color: #2f9f13; }

.color-limerick {
  color: #95bb20; }

.color-la-rioja {
  color: #c8d300; }

.color-yellow {
  color: #ffff00; }

.color-star-dust {
  color: #9d9d9c; }

.color-pink-swan {
  color: #b3b2b2; }

.color-arsenic {
  color: #39424a; }

.color-rhino {
  color: #444c55; }

.color-shuttle-grey {
  color: #51626f; }

.color-alizarin {
  color: #ec1c24; }

.color-white-smoke {
  color: #f2f2f2; }

.color-fire-engine-red {
  color: #ce1126; }

.color-ship-grey {
  color: #575756; }

.color-oyster-bay {
  color: #e1f1f1; }

.color-mabel {
  color: #c1ecec; }

.color-turquoise-blue {
  color: #56dce0; }

.color-panache {
  color: #eaf5e7; }

.color-azure {
  color: #f0f8f8; }

.color-gainsboro {
  color: #d9d9d9; }

.color-aluminium {
  color: #929496; }

.color-aubergine {
  color: #262324; }

.color-daisy-bush {
  color: #553995; }

.color-iris-blue {
  color: #12bccd; }

.color-radical-red {
  color: #f04b54; }

.color-crimson {
  color: #eb1745; }

.color-dark-tangerine {
  color: #fdb913; }

.color-cobalt {
  color: #0059a8; }

.bg-color-black {
  background-color: #000000; }

.bg-color-onyx {
  background-color: #111111; }

.bg-color-dark-jungle {
  background-color: #222222; }

.bg-color-mine-shaft {
  background-color: #333333; }

.bg-color-tundora {
  background-color: #444444; }

.bg-color-davys-grey {
  background-color: #555555; }

.bg-color-ironside-gray {
  background-color: #666666; }

.bg-color-tapa-gray {
  background-color: #777777; }

.bg-color-taupe-gray {
  background-color: #888888; }

.bg-color-mountain-mist {
  background-color: #999999; }

.bg-color-dark-gray {
  background-color: #aaaaaa; }

.bg-color-gray {
  background-color: #bbbbbb; }

.bg-color-celeste {
  background-color: #cccccc; }

.bg-color-light-gray {
  background-color: #dddddd; }

.bg-color-eallery {
  background-color: #eeeeee; }

.bg-color-white {
  background-color: #ffffff; }

.bg-color-yellow {
  background-color: #ffff00; }

.bg-color-navy-blue {
  background-color: #0079c1; }

.bg-color-prussian-blue {
  background-color: #00426a; }

.bg-color-error {
  background-color: #ec1c24; }

.bg-color-lima {
  background-color: #78b226; }

.bg-color-grey-chateau {
  background-color: #939597; }

.bg-color-orange {
  background-color: #fea300; }

.bg-color-malachite {
  background-color: #20bc55; }

.bg-color-light-sea-green {
  background-color: #1aa3a7; }

.bg-color-forest-green {
  background-color: #2f9f13; }

.bg-color-limerick {
  background-color: #95bb20; }

.bg-color-la-rioja {
  background-color: #c8d300; }

.bg-color-yellow {
  background-color: #ffff00; }

.bg-color-star-dust {
  background-color: #9d9d9c; }

.bg-color-pink-swan {
  background-color: #b3b2b2; }

.bg-color-arsenic {
  background-color: #39424a; }

.bg-color-rhino {
  background-color: #444c55; }

.bg-color-shuttle-grey {
  background-color: #51626f; }

.bg-color-alizarin {
  background-color: #ec1c24; }

.bg-color-white-smoke {
  background-color: #f2f2f2; }

.bg-color-fire-engine-red {
  background-color: #ce1126; }

.bg-color-ship-grey {
  background-color: #575756; }

.bg-color-oyster-bay {
  background-color: #e1f1f1; }

.bg-color-mabel {
  background-color: #c1ecec; }

.bg-color-turquoise-blue {
  background-color: #56dce0; }

.bg-color-panache {
  background-color: #eaf5e7; }

.bg-color-azure {
  background-color: #f0f8f8; }

.bg-color-gainsboro {
  background-color: #d9d9d9; }

.bg-color-aluminium {
  background-color: #929496; }

.bg-color-aubergine {
  background-color: #262324; }

.bg-color-daisy-bush {
  background-color: #553995; }

.bg-color-iris-blue {
  background-color: #12bccd; }

.bg-color-radical-red {
  background-color: #f04b54; }

.bg-color-crimson {
  background-color: #eb1745; }

.bg-color-dark-tangerine {
  background-color: #fdb913; }

.bg-color-cobalt {
  background-color: #0059a8; }

.color-torch {
  color: #FF2444; }

nav,
button,
.b-accordion__button::before,
.b-accordion__button::after {
  display: none !important; }

.b-footnotes,
.b-accordion__article,
.b-bod--animated-blocks {
  display: block; }

.b-accordion__button,
.b-accordion__article,
.b-footer {
  background: none; }

main.content {
  width: 100%; }

.b-copyright {
  page-break-after: always; }

.b-accordion__section {
  page-break-inside: auto; }

p {
  widows: 4;
  orphans: 3; }

.b-footnotes {
  font-size: 0.9em; }

.b-tooltip-number {
  display: inline; }

.b-tabs__article {
  position: relative !important;
  height: 100% !important;
  left: 0 !important;
  top: 0 !important; }

.b-title {
  top: 27px;
  left: 300px;
  font-weight: bold; }

.b-logo {
  bottom: inherit;
  top: 13px; }

.b-layout-wrapper--footer,
.b-layout-wrapper--header {
  display: none; }

.b-cookie-law {
  display: none; }

.b-indicator__value--print-cloned {
  display: none !important; }

.b-indicator__value--print-block {
  display: block !important; }

.b-indicator__value--print-inline-flex {
  display: inline-flex !important; }

.b-indicator__value--print-flex {
  display: flex !important; }

.b-indicator__value--print-table {
  display: table !important; }

.b-indicator__value--print-table-cell {
  display: table-cell !important; }

.b-indicator__value--print-inline-block {
  display: inline-block !important; }

.l-layout-wrapper .b-table[data-visible-rows] tr {
  display: table-row !important; }

.l-layout-wrapper .lazyload,
.l-layout-wrapper .lazyloading {
  opacity: 1 !important; }

.l-layout-wrapper table {
  position: static !important;
  opacity: 1 !important;
  visibility: visible !important; }

.l-layout-wrapper .b-fixed-head {
  display: none !important; }

.l-layout-wrapper .l-footer__nav {
  display: none; }

.l-layout-wrapper .b-bod--gallery {
  page-break-before: always; }

.l-layout-wrapper .b-bod--gallery .b-bod-expandable-content {
  page-break-after: always; }

.l-layout-wrapper .inset {
  color: #231f20; }
  .l-layout-wrapper .inset figcaption {
    color: #231f20 !important; }
  .l-layout-wrapper .inset ul li:before {
    border-color: transparent transparent transparent #231f20 !important; }
